@import '../../../../../assets/css/variables.scss';
@import '../../../../../assets/css/mixin.scss';



@keyframes headShake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  6.5% {
    -webkit-transform: translateX(-6px) rotateY(-9deg);
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    -webkit-transform: translateX(5px) rotateY(7deg);
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    -webkit-transform: translateX(-3px) rotateY(-5deg);
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    -webkit-transform: translateX(2px) rotateY(3deg);
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}


.plan-wrapper {
  position: relative;
  min-height: 300px;
  overflow-x: hidden;
  padding-top: 20px;
  @media (min-width: $medium) {
    padding-top: 50px;
  }
  &.has-selected-offers {
    padding-bottom: 70px;
  }

  .plans {
    padding: 20px 0px 80px;
    &.odd-plan {
      // background: transparent;
    }
    &.even-plan {
      // background: $white;
    }
    .blank-page {
      height: 290px;
    }
    .table-wrapper {
      margin-top: 40px;
      @media (min-width: $medium) {
        margin-bottom: 40px;
      }
    }
  }

  .services {
    @media screen and (min-width: $small) {

      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
    @media screen and (min-width: $medium) {
      max-width: 1280px;
      margin: 0 auto;
    }

    .services__multiple-items .service-list-item {
      @media screen and (max-width: $medium) {
        max-width:400px;
      }
    }
      


    .heading3-icon {
      margin-bottom: 30px;
    }

    .service-list-carousel {
      margin-top: 25px;
      position: relative;
      padding-left: 20px;
      @media (min-width: $small) {
        margin-right: -20px;
        padding: 0;
      }
      @media (min-width: $medium) {
        margin-right: 0;
      }
      .blank-page {
        padding-left: 0px;
        @media (min-width: $medium) {
          padding-left: 0;
          padding-right: 0;
          height: 800px;
          align-items: flex-start;
          padding-top: 220px;
        }
      }
      .slick-slider {
        .slick-list {
          // overflow: hidden;
          .slick-track {
            display: flex;
            .slick-slide {
              padding-right: 20px;
              display: flex;
              height: auto;
              align-items: center; //optional
              justify-content: center; //optional
              & > div {
                height: 100%;
                width: 100%;
              }
            }
          }
        }

        .slick-arrow {

          &.slick-prev,
          &.slick-next {
            top: 60px;
            width: 80px;
            padding: 5px;
            height: 80px;
            z-index: 99;
            // &.slick-disabled {
            //   display: none !important;
            //   opacity: 0;
            // }
          }
          &.slick-next {
            right: 2px;
            &:before {
              font-size: 70px;
              color:#AAA;
            }
          }
          &.slick-prev {
            left: -16px;
            &:before {
              font-size: 70px;
              color:#AAA;
            }
          }
        }
      }
    }
  }
}
.service-list {
  @include reset-list;
  margin: 0 !important;
  border-right: none;
  position: relative;
  &.no-promocodes .service-list-item:not(.has-promocode){
    margin-top: 0;
    height: 100%;
  }
  .service-list-item {

    // background: $white;
    // border: 1px solid $darker-grey;
    // padding: 20px;
    position: relative;
    min-height: 100%;
    height: 100%;
    overflow: hidden;
    // max-width: 75vw;
    // @media (min-width: $small) {
    //   // padding: 30px;
    //   max-width: 33vw;
    // }
    // @media (min-width: $medium) {
    //   // padding: 30px;
    //   max-width: 25vw;
    // }
    // @media (min-width: $large) {
    //   // padding: 30px;
    //   max-width: 20vw;
    // }
    & + .service-list-item {
      border-left: none;
    }

    $promo-message-height: 110px;
    &.has-promocode .promo-message {
      height: $promo-message-height;
    }
    &:not(.has-promocode) {
      @media (min-width: $small) {
        margin-top: $promo-message-height;
        // This height change is here to avoid changing the overflow on the slideshow, which would result in having a vertical scrollbar
        min-height: calc(100% - #{$promo-message-height});
        height: calc(100% - #{$promo-message-height});
      }
    }

    .service-card {
      display:flex;
      flex-direction: column;
      justify-content: space-between;
      // max-width: 250px;
      // // padding-bottom: 140px;
      // @media (min-width: $small) {
      //   max-width: 280px;
      // }
      .basic-details .MuiTypography-root.detail-link {
        pointer-events: none;
      }
      // .feature-list {
      //   max-height: 170px;
      //   overflow: auto;
      // }

      .footer-btn {
        margin-top: 20px;
        // position: absolute;
        // bottom: 0;
        // width: 100%;
        .btn-wrapper.select__button .btn{
          padding-top: 20px !important;
          padding-bottom: 20px !important;
          font-size: 18px;
          font-weight: 800;

          
          .MuiButton-label  {
            animation-iteration-count: 1;
            animation-timing-function: ease-in-out;
            animation-delay: 5s;
            animation-name: headShake;
            animation-duration: 1s;
            animation-fill-mode: both;
            
          }
        }
      }
    }
    &.selected {
      // background: rgba(0, 98, 255, 0.1);
      // border: 1px solid $primary-color;
    }
  }
}

.search-identifier-dialog {
  .search-input {
    @media (min-width: $small) {
      margin-right: 20px;
    }
    .MuiFormHelperText-root.MuiFormHelperText-contained {
      @media (min-width: $small) {
        position: absolute;
        bottom: -20px;
      }
    }
  }
}

.services__one-item .slick-center{
  @media (max-width: $small){
    margin: 0 auto;
  }
}

.not-available{
  // color: $error-color;
}

body[dir="rtl"]{
  .plan-wrapper{
    text-align: right;
  }
}

.slick-slider .image-container img {
  display: inline-block;
}

